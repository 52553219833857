import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <path
        fill="#129D51"
        d="M10.756.003L10.535 0C8.208 0 6.37 1.234 3.745 3.918L1.934 5.807l-.218.22C-.7 8.441-.49 13.908 1.876 20.181 4.651 27.54 10.13 35.524 17.304 42.7c7.178 7.176 15.157 12.651 22.513 15.424l.733.268c5.833 2.067 10.888 2.208 13.272.032l.733-.712 1.244-1.185c2.964-2.854 4.298-4.794 4.194-7.283-.092-2.23-1.58-4.428-4.292-6.608-5.717-4.597-9.083-5.074-12.51-2.203l-.21.179c-.31.268-.605.545-1.017.952l-1.473 1.484c-.998.998-2.92.738-5.968-1.244-2.269-1.476-5.095-3.877-8.378-7.13l-.413-.41c-8.142-8.142-10.633-12.906-8.776-14.767l.65-.642c4.946-4.867 5.276-7.694-.243-14.559-2.186-2.712-4.38-4.197-6.608-4.293zm-.221 2.854l.128.002c1.216.052 2.755 1.095 4.475 3.228 2.57 3.197 3.509 5.264 3.339 6.722-.124 1.062-.685 1.84-2.66 3.797l-.875.865c-3.608 3.616-.52 9.523 8.77 18.812 3.589 3.59 6.697 6.253 9.254 7.916 4.045 2.63 7.341 3.077 9.552.863l1.293-1.304c.498-.497.802-.783 1.126-1.06l.09-.075c2.178-1.824 4.05-1.6 8.618 2.027l.267.213c2.133 1.715 3.176 3.255 3.228 4.5.055 1.315-.876 2.716-3.081 4.872l-.887.851-.766.73c-.165.16-.315.306-.457.45-1.361 1.36-5.816 1.19-11.122-.815-6.948-2.62-14.595-7.866-21.502-14.772C12.42 33.774 7.17 26.123 4.549 19.172 2.545 13.86 2.374 9.408 3.735 8.048l.67-.687L5.53 6.183c2.238-2.324 3.666-3.326 5.006-3.326z"
      />
    </svg>
  )
}

export default SvgComponent

