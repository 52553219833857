import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <path
        fill="#129D51"
        d="M30 0C12.972 0 0 10.845 0 25.305l.003.415c.112 8.697 3.78 16.093 12.343 20.64l.183.095v12.182l.006.12c.085 1.02 1.254 1.598 2.123 1.003l13.489-9.224 1.032.054c.299.013.55.018.82.018C47.03 50.608 60 39.765 60 25.305S47.03 0 30 0zm0 2.72c15.625 0 27.28 9.745 27.28 22.585 0 12.839-11.656 22.582-27.28 22.582l-.422-.005a27.06 27.06 0 01-.476-.018l-.963-.052-.078-.007a2.19 2.19 0 00-1.327.401L15.25 56.06v-9.841a2.32 2.32 0 00-1.255-2.067C6.01 40.069 2.721 33.378 2.721 25.305 2.72 12.465 14.377 2.721 30 2.721z"
      />
    </svg>
  )
}

export default SvgComponent
