import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <path
        d="M30 0c-9.617 0-13 5.193-13 15.5C17 24.005 22.752 31 30 31s13-6.995 13-15.5C43 5.193 39.617 0 30 0zm0 2.875c7.71 0 10.128 3.713 10.128 12.625 0 7.027-4.603 12.625-10.128 12.625S19.872 22.527 19.872 15.5l.005-.702C20.005 6.393 22.497 2.875 30 2.875zm6.509 28.277l-.84-.152-.528.672c-.132.167-.491.497-1.09.85-1.057.621-2.394 1-4.051 1s-2.994-.379-4.051-1c-.6-.353-.959-.683-1.09-.85L24.33 31l-.84.152C10.588 33.48 7.43 37.074 7.05 49.01l-.032 1.334A91.814 91.814 0 007 51.957v.572l.172.326c.223.414.611.93 1.212 1.51.885.856 2.066 1.682 3.58 2.43C16.05 58.817 21.95 60 30 60c8.265 0 14.258-1.246 18.344-3.36 1.488-.77 2.63-1.61 3.469-2.47.544-.558.875-1.03 1.04-1.36l.147-.3-.005-1.076c-.003-.353-.01-.764-.019-1.245l-.017-.765c-.343-12.32-3.428-15.924-16.45-18.273zm.143 3l.085-.074.314.06c10.872 2.093 12.84 4.773 13.08 15.755l.03 1.863-.067.085a5.294 5.294 0 01-.313.348c-.625.64-1.52 1.3-2.741 1.931-3.65 1.889-9.202 3.043-17.04 3.043-7.638 0-13.113-1.098-16.778-2.91-1.254-.62-2.193-1.277-2.865-1.927l-.123-.122a5.683 5.683 0 01-.295-.32l-.1-.124.014-1.191.024-1.096.02-.679c.34-9.906 2.462-12.565 12.692-14.586l.673-.13.086.074a8.44 8.44 0 001.162.815c1.485.873 3.316 1.392 5.49 1.392 2.174 0 4.005-.519 5.49-1.392a8.44 8.44 0 001.162-.815z"
        fill="#129D51"
      />
    </svg>
  )
}

export default SvgComponent